// store/index.js

import Vue from "vue";
import Vuex from "vuex";
import lang from "./modules/lang.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    lang
  },
  state: {
    appName: 'Goodyear Car Dealer Portal',
    user: {
      auth: false,
      idToken: '',
      email: '',
      nonsig: '',
      name: '',
      oid: '',
      role: null
    },
    authPending: false
  },
  getters: {
    appName: state => {
      if(state.lang.language.toUpperCase()==='FR-CA') {
        state.appName = 'Portail Goodyear pour les concessionnaires automobiles'
      } else {
        state.appName = 'Goodyear Car Dealer Portal'
      }
      return state.appName
    },
    auth: state => {
      return state.user.auth
    },
    authPending: state => {
      return state.authPending
    },
    userRole: state => {
      return state.user.role
    },
    userNonsig: state => {
      return state.user.nonsig;
    },
    isUserAdmin: state => {
      return state.user.role === 'admin' || state.user.role === 'sales rep';
    }
  },
  // Mutations are the only way to properly change the value of the state object. 
  // An important detail to note is that mutations must be synchronous.
  mutations: {
    changeAuth(state, payload) {
      let isAuth = payload.isAuthenticated();
      state.user.auth = isAuth;
    },
    setUserEmail(state, payload) {
      state.user.email = payload;
    },
    setUserNonsig(state, payload) {
      state.user.nonsig = payload;
    },
    setUserName(state, payload) {
      state.user.name = payload;
    },
    setUserOid(state, payload) {
      state.user.oid = payload;
    },
    setUserIdToken(state, payload) {
      state.user.idToken = payload;
    },
    setUserRole(state, payload) {
      state.user.role = payload;
    },
    setAuthPending(state, payload) {
      state.authPending = payload;
    }
  },
  actions: {
    clearUser ({ commit }) {
      commit('setUserEmail', '');
      commit('setUserNonsig', '');
      commit('setUserName', '');
      commit('setUserOid', '');
      commit('setUserIdToken', '');
      commit('setUserRole', null);
    }
  }
});