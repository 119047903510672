<template>
  <div class="pending-nonsig-users" role="main">

    <b-container v-if="!showError" class="user-maintenance">
      <b-row>
        <b-col cols="12">
          <h1>{{languageData.title}}</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="7" lg="8">
          <b-button class="btn-approve pr-12px" :disabled="!selectedUser" v-b-modal.approve-modal>{{languageData.approve_btn}} <b-icon icon="check"></b-icon></b-button>
          <b-button class="btn-deny pr-12px" :disabled="!selectedUser" v-b-modal.deny-modal>{{languageData.deny_btn}} <b-icon icon="x"></b-icon></b-button>
        </b-col>
        <b-col cols="12" md="5" lg="4">
          <b-input-group>
            <b-form-input v-model="filter" class="search-input" :placeholder="languageData.search"></b-form-input>
            <b-input-group-append>
              <b-button class="clear-search-btn" @click="filter = ''">{{languageData.clear_btn}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            :items="pendingNonsigUsers" 
            primary-key="oid" 
            :fields="fields" 
            :filter="filter" 
            :sort-by.sync="sortBy" 
            :sort-desc.sync="sortDesc"
            selectable
            select-mode="single"
            @row-selected="onRowSelected"
            :current-page="currentPage"
            :per-page="perPage"
            hover
            @filtered="onFiltered"
            :busy="loading"
            responsive
            v-bind:class="{ 'border-bottom': !loading }"
            show-empty
          >
            <template #cell(show_details)="row">
              <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                {{ row.detailsShowing ? '-' : '+'}} Details
              </b-button>
            </template>
            <template #table-busy>
              <div class="text-center">
                <b-spinner :label="languageSharedData.loading"></b-spinner>
                <p><strong>{{languageSharedData.loading}}</strong></p>
              </div>
            </template>
            <template #empty>
              <div class="text-center">
                <h4>{{languageData.no_nonsigs}}</h4>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <h4>{{languageSharedData.no_records}}</h4>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row v-if="!loading && totalRows">
        <b-col cols="12" sm="8" md="6">
          <b-pagination 
            v-model="currentPage" 
            :total-rows="totalRows"
            :per-page="perPage"
            page-class="pagination-item"
          ></b-pagination>
        </b-col>
        <b-col cols="12" sm="4" md="6">
          <div class="current-page-range">
            {{currentPageRange}}
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Approve user modal-->
    <b-modal
      v-if="selectedUser"
      id="approve-modal"
      :title="languageData.approve_modal_title"
      header-class="gy-modal-header"
      @ok="resolvePendingNonsigUser('approve')"
    >
      <p class="my-4">{{evaluateMsg(languageData.approve_modal_message, {'nonsig': selectedUser.nonsig, 'pending_nonsig': selectedUser.pending_nonsig})}}</p>

      <template #modal-footer="{ cancel, ok }">
        <b-button class="modal-cancel-btn" @click="cancel()">
          {{languageData.cancel_btn}}
        </b-button>
        <b-button class="btn-approve" @click="ok()">
          {{languageData.approve_btn}}
        </b-button>
      </template>
    </b-modal>

    <!-- Deny user modal-->
    <b-modal
      v-if="selectedUser"
      id="deny-modal"
      :title="languageData.deny_modal_title"
      header-class="gy-modal-header"
      @ok="resolvePendingNonsigUser('deny')"
    >
      <p class="my-4">{{evaluateMsg(languageData.deny_modal_message, {'nonsig': selectedUser.nonsig, 'pending_nonsig': selectedUser.pending_nonsig})}}</p>

      <template #modal-footer="{ cancel, ok }">
        <b-button class="modal-cancel-btn" @click="cancel()">
          {{languageData.cancel_btn}}
        </b-button>
        <b-button class="btn-deny" @click="ok()">
          {{languageData.deny_btn}}
        </b-button>
      </template>
    </b-modal>

    <b-container v-if="showError && !loading">
      <h5 class="error">{{languageSharedData.error}}</h5>
      <p>{{languageSharedData.try_again}}</p>
    </b-container>

  </div>
</template>

<script>
import dataService from '../../services/data.service';

export default {
  name: 'nonsig-changes',
  metaInfo () {
    return {
      title: this.languageData.page_title + ' | ' + this.$store.getters["appName"],
    }
  },
  data() {
    return {
      sortBy: 'first_name',
      sortDesc: false,
      filter: '',
      pendingNonsigUsers: [],
      selectedUser: null,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      loading: false,
      showError: false,
      showApproveModal: false,
      showDenyModal: false,
    }
  },
  mounted () {
    this.getPendingNonsigUsers()
  },
  methods : {
    getPendingNonsigUsers: async function () {
      this.loading = true;
      this.pendingNonsigUsers = [];
      try {
        let response = await dataService.getPendingNonsigUsers()
        this.loading = false
        if (response.data === 0) {
          this.showError = true
        } else {
          this.pendingNonsigUsers = response.data;
          this.totalRows = response.data.length;
        }
      } catch {
        // do nothing
      }
    },
    onRowSelected(rows) {
      this.selectedUser = rows[0];
    },
    resolvePendingNonsigUser: async function (action) {
      try {
        this.loading = true;
        await dataService.resolvePendingNonsigUser({oid: this.selectedUser.oid}, action);
        this.getPendingNonsigUsers();
      } catch {
        this.loading = false;
        this.showError = true
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    evaluateMsg(msg, params) {
      for(const key in params) {
        msg = msg.replace(`{{${key}}}`, params[key]);
      }
      return msg;
    }
  },
  computed: {
    currentPageRange: function() {
      let first = (this.currentPage - 1) * this.perPage + 1;
      let last = this.currentPage === this.filteredPageCount ? this.totalRows : first + this.perPage - 1;
      return `${first} - ${last} ${this.languageData.out_of} ${this.totalRows}`
    },
    filteredPageCount: function() {
      return Math.ceil(this.totalRows / this.perPage);
    },
    languageSharedData: function () {
      return this.$store.state.lang.languageData['shared'][this.$store.state.lang.language];
    },
    languageData: function () {
      return this.$store.state.lang.languageData[this.$route.name.toLowerCase()][this.$store.state.lang.language];
    },
    fields: function(){
      return [
        {key: 'first_name', label: this.languageData.table.first_name, sortable: true},
        {key: 'last_name', label: this.languageData.table.last_name, sortable: true},
        {key: 'email', label: this.languageData.table.email, sortable: true},
        {key: 'nonsig', label: this.languageData.table.nonsig, sortable: true},
        {key: 'pending_nonsig', label: this.languageData.table.pending_nonsig, sortable: true},
        {key: 'nonsig_requested_date', label: this.languageData.table.requested_date, sortable: true}
      ]
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .pending-nonsig-users {
    padding-top: 50px;
    min-height: 450px;
  }

  .btn-approve {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }

  .btn-approve:hover, .btn-approve:focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }

  .btn-deny {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .btn-deny:hover, .btn-deny:focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }

  .pr-12px {
    padding-right: 12px;
  }

  .search-input {
    margin-top: 10px;
    height: 39px;
  }

  .btn-approve, .clear-search-btn {
    margin-left: 0px;
  }

  .clear-search-btn {
    margin-right: 0px;
  }

  ::v-deep .page-item.disabled {
    cursor: not-allowed;
  }

  ::v-deep .page-link {
    color: #000;
    font-family: "Barlow-SemiCondensed-Bold", Arial, sans-serif;
  }

  ::v-deep .page-item.active > .page-link {
    color: #000;
    background-color: #fd0;
    border-color: #fd0;
  }

  ::v-deep .b-table-row-selected {
    background-color: #93c5ff !important;
  }

  ::v-deep .b-table-empty-row {
    cursor: default !important;
  }

  ::v-deep .gy-modal-header{
    background-color: #274ba7;  
  }

   ::v-deep .gy-modal-header > .modal-title {
    color: #fd0; 
    font-family: "Barlow-SemiCondensed-Bold", Arial, sans-serif;
    font-size: 2.0rem;
  }

  ::v-deep .gy-modal-header > .close {
    color: #fff;
    font-size: 3.0rem;
    text-shadow: none;
    opacity: 1;
  }

  ::v-deep .gy-modal-header > .close:hover {
    color: #fd0;
    opacity: 1 !important;
  }

  ::v-deep .modal-cancel-btn {
    color: #343a40;
    border: 2px solid transparent;
    border-color: #343a40;
    background-color: transparent;
  }

  ::v-deep .modal-cancel-btn:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }

  .current-page-range {
    font-weight: bold;
    margin-top: 0.35rem;
    text-align: right !important;
  }

  @media (max-width:576px) {
    .current-page-range{
      margin-top: 0px;
      margin-bottom: 0.6rem;
    }
  }

  @media (max-width:768px) {
    .pending-nonsig-users {
      margin-top: 10px;
    }
  }

  @media (min-width:768px) and (max-width:1100px) {
    h1 {
      font-size: 2.15rem;
    }
  }

  @media (min-width: 1600px) {
    .pending-nonsig-users {
      width: 1600px;
      min-height: 550px;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
  }

</style>
