<template>
  <div class="loadingOverlay">
    <b-icon icon="arrow-repeat" animation="spin" font-scale="1.25"></b-icon><br>
    {{loading}}
  </div>
</template>

<script>
export default {
  name: "LoadingOverlay",
  data() {
    return {
      loading: this.$store.state.lang.language==='fr-CA' ? 'Chargement...' : 'Loading...' //language data from DB doesn't available at this moment
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .loadingOverlay {
    position: absolute;
    text-align: center;
    background-color: #274ba7;
    width: 100%;
    height: calc(100vh + 62px);
    z-index: 200;
    font-size: 32px;
    color: white;
    padding-top: calc(50vh - 102px);
  }

</style>
