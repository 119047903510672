import axios from 'axios'
import { msalPluginInstance } from "../plugins/MSALPlugin"
import router from '../router'

const getTokenAndCallApi = async (config) => {
  return msalPluginInstance.acquireAccessToken(async (access_token) => {
    // azure swa replaces 'authorization' header with its own so we use custom one
    config.headers = {
      'x-custom-authorization': `Bearer ${access_token}`
    };
    try {
      return await axios(config);
    } catch (err) {
      if (err.response.status === 403) {
        router.push({ name: 'forbidden' });
      } else {
        //do we need to handle error 401? 
      }
      //return data to avoid errors in console
      return { data: { data: { length: 0 } } };
    }
  });
};

export default class UserService {

  /**
   * User role
   */
  async getUserRole() {
    return getTokenAndCallApi({ url: '/api/getUserRole', method: 'POST' });
  }

  /**
   * Load user profile data
   */
   async getProfile(oid) {
    return getTokenAndCallApi({ url: `/api/profile/${oid}`, method: 'GET'});
  }

  /**
   * Update user profile data
   */
   async updateProfile(oid, data) {
    return getTokenAndCallApi({ url: `/api/profile/${oid}`, method: 'PUT', data});
  }
}