import axios from 'axios';
import store from '../store'

export default {

  /**
   * get all languages data
   */
  async getLanguage(pageName) {
    return axios.post('/api/getLanguage', {pageName: pageName });
  },

  /**
   * get language data by content_name from store
   * if there is no data for given content_name then load data from DB first
   */
  async getLanguageData(pageName) {
    if (store.state.lang.languageData[pageName]) {
      return false;
    }
    try {
      let response = await this.getLanguage(pageName);
      if (response.data) {
        store.commit("lang/updateLanguageData", response.data);
      }
    } catch {
      // do nothing
    }
  }

}