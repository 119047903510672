import Vue from 'vue'
import Router from 'vue-router'

import Contact from '@/components/Contact'
import Resources from '@/components/Resources'
import Dashboard from '@/components/Dashboard'
import Login from '@/components/Login'
import Logout from '@/components/Logout'
import Signup from '@/components/Signup'
import PendingUsers from '@/components/admin/PendingUsers'
import Profile from '@/components/admin/Profile'
import NonsigChanges from '@/components/admin/NonsigChanges'
import error404 from '@/components/404'
import AccessDenied from '@/components/AccessDenied'
import { msalPluginInstance } from '@/plugins/MSALPlugin'

import store from '../store'

import UserService from '../services/user.service';
const userService = new UserService();

import langService from '../services/lang.service';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/:lang(en-CA|fr-CA)?/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/:lang(en-CA|fr-CA)?/resources',
      name: 'Resources',
      component: Resources
    },
    {
      path: '/:lang(en-CA|fr-CA)?/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/:lang(en-CA|fr-CA)?/',
      name: 'Login',
      component: Login
    },
    {
      path: '/:lang(en-CA|fr-CA)?/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '/:lang(en-CA|fr-CA)?/signup',
      name: 'Signup',
      component: Signup
    },
    {
      path: '/signin',
      name: 'Signin'
    },
    {
      path: '/:lang(en-CA|fr-CA)?/admin/pending-users',
      name: 'PendingUsers',
      component: PendingUsers
    },
    {
      path: '/:lang(en-CA|fr-CA)?/admin/profile',
      name: 'Profile',
      component: Profile
    },
    {
      path: '/:lang(en-CA|fr-CA)?/admin/nonsig-changes',
      name: 'NonsigChanges',
      component: NonsigChanges
    },
    {
      path: '/:lang(en-CA|fr-CA)?/access-denied',
      name: 'access-denied',
      component: AccessDenied,
      props: true,
    },
    {
      path: '/:lang(en-CA|fr-CA)?/404-error',
      name: 'error-404',
      component: error404
    },
    {
      path: '*',
      redirect: '/404-error'
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  let lang = to.params.lang;
  if(!lang) {
    const query = { ...to.query };
    const params = { ...to.params, lang: store.state.lang.language };
    router.replace({name: to.name, params, query});
    return;
  } else if (lang != store.state.lang.language) {
    lang = lang.toLocaleUpperCase()==='FR-CA' ? 'fr-CA' : 'en-CA'; // check case of lang param in URL
    store.commit("lang/changeLanguage", lang);
  }

  await langService.getLanguageData('shared');
  await langService.getLanguageData(to.name.toLowerCase());

  if (to.name == 'Logout' || to.name == 'Signup' || to.name == 'error-404' ||  to.name == 'access-denied') {
    next();
    return;
  }

  if (to.name == 'Login') {
    if(msalPluginInstance.isAuthenticated()) {
      // user logged in - send to dashboard
      router.replace({name: 'Dashboard'});
    } else {
      // user not logged in
      next();
    }
    return;
  }

  // Authentication
  if(!msalPluginInstance.isAuthenticated() || !store.getters.auth){
    store.commit("setAuthPending", true);

    // store requested route if it is not Signin route after loginRedirect
    if (to.name !== 'Signin') {
      sessionStorage.setItem('requestedRoute', to.name);
    }

    if (await msalPluginInstance.signIn()) {
      // Role assignment
      const userRoleResponse = await userService.getUserRole();
      if(userRoleResponse.data.userRole) {
        store.commit("setUserRole", userRoleResponse.data.userRole);
      } else {
        await msalPluginInstance.signOutAndAccessDenied(userRoleResponse.data.error);
      }
      store.commit("setAuthPending", false);

      if (sessionStorage.getItem('requestedRoute') === to.name) {
        sessionStorage.removeItem('requestedRoute');
      } else {
        router.replace({name: sessionStorage.getItem('requestedRoute')});
        sessionStorage.removeItem('requestedRoute');
        return;
      }
    }
  }

  if(to.name === 'Signin') {
    router.replace({name: 'Dashboard'});
    return;
  }

  if(msalPluginInstance.isAuthenticated() && store.getters.auth && store.getters.userRole) {
    if ((to.name === 'Profile' || to.name === 'PendingUsers' || to.name === 'NonsigChanges') && store.getters.isUserAdmin) {
      next();
      return;
    }

    if(to.name === 'Dashboard' || to.name === 'Resources' || to.name === 'Contact') {
      next();
      return;
    }
  }

  router.replace({name: 'access-denied'});

});

Vue.use(Router)

export default router
