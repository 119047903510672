<template>
  <div class="profile" role="main">

    <b-container>
      <b-row>
        <b-col cols="12">
          <h1>{{languageData.title}}</h1>
        </b-col>
        <b-col cols="12" md="7" lg="7" class="user-details">
          <b-icon icon="person-circle"></b-icon>
          <span class="name">{{$store.state.user.name}}</span>
          <br/>
          <span>{{$store.state.user.email}}</span>
        </b-col>
        <b-col cols="12" md="5" lg="5" class="user-pref" v-if="!loading && !showError">
          <h2>{{languageData.email_lang_pref}}</h2>
          <b-form-group>
            <b-form-radio v-model="langPref" @change="isDisabled=false" name="lang-pref-radios" value="en" size="lg">English</b-form-radio>
            <b-form-radio v-model="langPref" @change="isDisabled=false" name="lang-pref-radios" value="fr" size="lg">Français</b-form-radio>
          </b-form-group>
          <b-button class="btn" :disabled="isDisabled || loading" @click="updateProfile">{{languageData.update_profile_btn}}</b-button>
        </b-col>
        <b-col v-if="loading">
          <b-spinner :label="languageSharedData.loading"></b-spinner>
          <p><strong>{{languageSharedData.loading}}</strong></p>
        </b-col>
        <b-col v-if="showError && !loading">
          <h5 class="error">{{languageSharedData.error}}</h5>
          <p>{{languageSharedData.try_again}}</p>
        </b-col>
      </b-row>
    </b-container>
   
  </div>
</template>

<script>
  import UserService from '../../services/user.service';
  const userService = new UserService();

  export default {
    name: 'Profile',
    metaInfo () {
      return {
        title: this.languageData.page_title + ' | ' + this.$store.getters["appName"],
      }
    },
    data() {
        return {
          langPref: 'en', // default is English
          loading: false,
          showError: false,
          isDisabled: true
        }
    },
    mounted () {
      this.getProfile()
    },
    methods : {
      async showAlert(message) {
        await this.$bvModal.msgBoxOk(message);
      },
      getProfile: async function () {
        this.loading = true
        try {
          let response = await userService.getProfile(this.$store.state.user.oid)
          this.loading = false
          if(response.data && response.data.data && response.data.data.language_preference) {
            this.langPref = response.data.data.language_preference
          }
        } catch {
          this.loading = false
          this.showError = true
        }
      },
      updateProfile: async function () {
        this.loading = true
        let data = {
          language_preference: this.langPref 
        }
        try {
          let response = await userService.updateProfile(this.$store.state.user.oid, data)
          this.loading = false
          if(response.data && response.data.code == 0) {
            this.isDisabled = true
            this.showAlert(this.languageData.update_success)
            window.dataLayer.push({ 'event': 'formSubmitted', 'formName': 'Update Profile' })
          } else {
            this.showError = true
          }
        } catch {
          this.loading = false
          this.showError = true
        }
      }
    },
    computed: {
      languageSharedData: function () {
        return this.$store.state.lang.languageData['shared'][this.$store.state.lang.language];
      },
      languageData: function () {
        return this.$store.state.lang.languageData[this.$route.name.toLowerCase()][this.$store.state.lang.language];
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .profile {
    padding-top: 50px;
    min-height: 450px;
  }
  h1 {
    margin-bottom: 25px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(0,0,0, .10);
  }
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .user-details {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 16px;
  }
  .name {
    font-size: 22px;
  }
  .bi-person-circle {
    height: 3em;
    width: 3em;
    margin-right: 10px;
    float: left;
  }
  .user-pref {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  @media (max-width:768px) {
    .profile {
      margin-top: 10px;
    }
  }

  @media (min-width:768px) and (max-width:1100px) {
    h1 {
      font-size: 2.15rem;
    }
  }

  @media (min-width: 1600px) {
    .profile {
      width: 1600px;
      min-height: 550px;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
  }
</style>
