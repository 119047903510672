<template>
  <div id="app">
    <LoadingOverlay v-if="this.$store.getters.authPending || !$store.state.lang.languageData.shared"/>
    <Header v-if="$store.state.lang.languageData.shared"/>
    <router-view/><!-- Language Data is accuired in Router -->
    <Footer v-if="$store.state.lang.languageData.shared"/>
  </div>
</template>

<script>
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  import LoadingOverlay from '@/components/LoadingOverlay'

  export default {
    name: "App",
    metaInfo() {
      return {
        htmlAttrs: {
          lang: this.$store.state.lang.language,
        },
        link: [
          {
            rel: 'canonical',
            href: `https://www.goodyearcardealer.com/${this.$store.state.lang.language}`}
        ],
        meta: [
          { 
            name: 'robots', 
            content: this.$route.name === 'Login' || this.$route.name === 'Signup' ? 'index, nofollow' : 'noindex, nofollow'
          }
        ]
      }
    },
    components: { Header, Footer, LoadingOverlay }
  };
</script>
