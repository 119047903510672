<template>
  <div class="login" role="main">
    <section class="intro-section">
      <b-container>
        <div class="text-center">
          <h1>{{languageData.title}}</h1>
          <br/>
          <a class="btn homeButton" v-on:click="$msal.signIn()">{{languageSharedData.sign_in}}</a>
          <router-link class="btn homeButton" :to="{name: 'Signup', params: {lang:$store.state.lang.language}}">{{languageSharedData.sign_up}}</router-link>
          <br/>
          <br/>
        </div>
      </b-container>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'Log-In',
    metaInfo () {
      return {
        title: this.languageData.page_title + ' | ' + this.$store.getters["appName"],
        meta: [
          { name: 'description', content: this.languageData.page_description }
        ]
      }
    },
    computed: {
      languageSharedData: function () {
        return this.$store.state.lang.languageData['shared'][this.$store.state.lang.language];
      },
      languageData: function () {
        return this.$store.state.lang.languageData[this.$route.name.toLowerCase()][this.$store.state.lang.language];
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .login {
    min-height: 400px;
  }
  .intro-section {
    padding-top: 50px;
    color: #fff;
    background-color: transparent;
    background-color: #6D6050;
    background-image: url('../assets/images/background/goodyear-tire-snow-treads-md.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  @media (max-width: 500px) {
    .intro-section {
      background-image: url('../assets/images/background/goodyear-tire-snow-treads-xs.jpg');
    }
  }
  @media (min-width: 501px) and (max-width: 768px) {
    .intro-section {
      background-image: url('../assets/images/background/goodyear-tire-snow-treads-sm.jpg');
    }
  }
  @media (min-width: 1281px) and (max-width: 1600px) {
    .intro-section {
      background-image: url('../assets/images/background/goodyear-tire-snow-treads-lg.jpg');
    }
  }
  @media (max-width: 992px) {
    .login, .intro-section {
      min-height: 350px;
    }
  }
  @media (min-width: 993px) {
    .intro-section {
      min-height: 650px;
      padding-top: 100px;
    }
  }
  @media (min-width: 1601px) and (max-width: 2500px ) {
    .intro-section {
      background-image: url('../assets/images/background/goodyear-tire-snow-treads-xl.jpg');
    }
  }
  @media (min-width: 2501px) {
    .intro-section {
      background-image: url('../assets/images/background/goodyear-tire-snow-treads.jpg');
    }
  }
</style>
