<template>
  <div class="pending-users" role="main">

    <b-container v-if="!showError" class="user-maintenance">
      <b-row>
        <b-col cols="12">
          <h1>{{languageData.title}}</h1>
          <p>{{languageData.message}} <a class="goodyearPassAdminLink" href="https://admin.goodyearpass.com/" target="_blank">GoodyearPass Admin Portal</a>.</p>
        </b-col>
        <b-col cols="12" md="7" lg="8">
          <b-button :disabled="!selectedItem" v-b-modal.approve-modal class="btn-approve pr-12px">{{languageData.approve_btn}} <b-icon icon="check"></b-icon></b-button>
          <b-button :disabled="!selectedItem" v-b-modal.deny-modal class="btn-deny pr-12px">{{languageData.deny_btn}} <b-icon icon="x"></b-icon></b-button>
        </b-col>
        <b-col cols="12" md="5" lg="4">
          <b-input-group>
            <b-form-input
              v-model="filter"
              type="search"
              :placeholder="languageData.search"
              class="search-input"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="filter=null" class="clear-search-btn">{{languageData.clear_btn}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-table
            responsive
            selectable
            select-mode='single'
            hover
            :items="pendingUsers"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            @filtered="onFiltered"
            @row-selected="selectRow"
            :busy="loading"
            v-bind:class="{ 'border-bottom': !loading }"
            show-empty
          >
            <template #cell(_ts)="row">
              {{ new Date(row.value*1000).toLocaleString() }}
            </template>
            <template #table-busy>
              <div class="text-center">
                <b-spinner :label="languageSharedData.loading"></b-spinner>
                <p><strong>{{languageSharedData.loading}}</strong></p>
              </div>
            </template>
            <template #empty>
              <div class="text-center">
                <h4>{{languageData.no_pending_users}}</h4>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <h4>{{languageSharedData.no_records}}</h4>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row v-if="!loading && totalRows">
        <b-col cols="12" sm="8" md="6">
          <b-pagination 
            v-model="currentPage" 
            :total-rows="totalRows"
            :per-page="perPage"
            page-class="pagination-item"
          ></b-pagination>
        </b-col>
        <b-col cols="12" sm="4" md="6">
          <div class="current-page-range">
            {{currentPageRange}}
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Approve user modal-->
    <b-modal
      v-if="selectedItem"
      id="approve-modal"
      :title="languageData.approve_modal_title"
      header-class="gy-modal-header"
      @ok="approve()"
    >
      <p class="my-4">{{evaluateMsg(languageData.approve_modal_message, {'first_name': selectedItem.first_name, 'last_name': selectedItem.last_name, 'email': selectedItem.email, 'dealerid': selectedItem.dealerid})}}</p>

      <template #modal-footer="{ cancel, ok }">
        <b-button class="modal-cancel-btn" @click="cancel()">
          {{languageData.cancel_btn}}
        </b-button>
        <b-button class="btn-approve" @click="ok()">
          {{languageData.approve_btn}}
        </b-button>
      </template>
    </b-modal>

    <!-- Deny user modal-->
    <b-modal
      v-if="selectedItem"
      id="deny-modal"
      :title="languageData.deny_modal_title"
      header-class="gy-modal-header"
      @ok="deny()"
    >
      <p class="my-4">{{evaluateMsg(languageData.deny_modal_message, {'first_name': selectedItem.first_name, 'last_name': selectedItem.last_name, 'email': selectedItem.email, 'dealerid': selectedItem.dealerid})}}</p>

      <template #modal-footer="{ cancel, ok }">
        <b-button class="modal-cancel-btn" @click="cancel()">
          {{languageData.cancel_btn}}
        </b-button>
        <b-button class="btn-deny" @click="ok()">
          {{languageData.deny_btn}}
        </b-button>
      </template>
    </b-modal>

    <b-container v-if="showError && !loading">
      <h5 class="error">{{languageSharedData.error}}</h5>
      <p>{{languageSharedData.try_again}}</p>
    </b-container>

  </div>
</template>

<script>
  import dataService from '../../services/data.service';

  export default {
    name: 'PendingUsers',
    metaInfo () {
      return {
        title: this.languageData.page_title + ' | ' + this.$store.getters["appName"],
      }
    },
    data() {
        return {
          sortBy: 'first_name',
          sortDesc: false,
          pendingUsers: [],
          totalRows: 0,
          currentPage: 1,
          perPage: 10,
          filter: null,
          loading: false,
          showError: false,
          selectedItem: null,
          showApproveModal: false,
          showDenyModal: false
        }
    },
    mounted () {
      this.getPendingUsers()
    },
    methods : {
      getPendingUsers: async function () {
        this.loading = true
        const admin = this.$store.state.user.email
        try {
          let response = await dataService.getPendingUsers(admin)
          this.loading = false
          if (response.data === 0) {
            this.showError = true
          } else {
            this.pendingUsers = response.data
            this.totalRows = response.data.length
          }
        } catch {
          // do nothing
        }
      },
      print: function() {
        window.print()
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      selectRow(items) {
        this.selectedItem = items[0];
      },
      approve: async function () {
        try {
          let response = await dataService.approve(this.selectedItem.id, this.selectedItem.email, this.$store.state.user.oid, this.selectedItem.nonsig);
          if(response.data.code == -2) {
            this.showAlert(response.data.message);
          } else if (response.data.code !== 0) {
            this.showErrorAlert();
          } else {
            this.showAlert(this.languageData.approved);
          }
          this.getPendingUsers();
        } catch {
          this.showErrorAlert();
        }
      },
      deny: async function () {
        try {
          await dataService.deny(this.selectedItem.id);
          this.showAlert(this.languageData.rejected);
          this.getPendingUsers();
        } catch {
          this.showErrorAlert();
        }
      },
      async showAlert(message, options) {
        await this.$bvModal.msgBoxOk(message, options);
      },
      async showErrorAlert() {
        await this.showAlert(this.languageSharedData.try_again, {
          titleHtml: `<h5 class="error">${this.languageSharedData.error}</h5>`,
          hideHeaderClose: false
        });
      },
      evaluateMsg(msg, params) {
        for(const key in params) {
          msg = msg.replace(`{{${key}}}`, params[key]);
        }
        return msg;
      }
    },
    computed: {
      currentPageRange: function() {
        let first = (this.currentPage - 1) * this.perPage + 1;
        let last = this.currentPage === this.filteredPageCount ? this.totalRows : first + this.perPage - 1;
        return `${first} - ${last} ${this.languageData.out_of} ${this.totalRows}`
      },
      filteredPageCount: function() {
        return Math.ceil(this.totalRows / this.perPage);
      },
      languageSharedData: function () {
        return this.$store.state.lang.languageData['shared'][this.$store.state.lang.language];
      },
      languageData: function () {
        return this.$store.state.lang.languageData[this.$route.name.toLowerCase()][this.$store.state.lang.language];
      },
      fields: function(){
        return [
          {key: 'first_name', label: this.languageData.table.first_name, sortable: true},
          {key: 'last_name', label: this.languageData.table.last_name, sortable: true},
          {key: 'email', label: this.languageData.table.email, sortable: true},
          {key: 'dealer_name', label: this.languageData.table.dealer_name, sortable: true},
          {key: 'dealerid', label: this.languageData.table.dealer_id, sortable: true},
          {key: 'sales_rep', label: this.languageData.table.sales_rep, sortable: true},
          {key: '_ts', label: this.languageData.table.date_submitted, sortable: true},
        ]
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .pending-users {
    padding-top: 50px;
    min-height: 450px;
  }

  .btn-approve {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }

  .btn-approve:hover, .btn-approve:focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }

  .btn-deny {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .btn-deny:hover, .btn-deny:focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }

  .pr-12px {
    padding-right: 12px;
  }

  .search-input {
    margin-top: 10px;
    height: 39px;
  }

  .btn-approve, .clear-search-btn {
    margin-left: 0px;
  }

  .clear-search-btn {
    margin-right: 0px;
  }

  ::v-deep .page-item.disabled {
    cursor: not-allowed;
  }

  ::v-deep .page-link {
    color: #000;
    font-family: "Barlow-SemiCondensed-Bold", Arial, sans-serif;
  }

  ::v-deep .page-item.active > .page-link {
    color: #000;
    background-color: #fd0;
    border-color: #fd0;
  }

  ::v-deep .b-table-row-selected {
    background-color: #93c5ff !important;
  }

  ::v-deep .b-table-empty-row {
    cursor: default !important;
  }

  ::v-deep .gy-modal-header{
    background-color: #274ba7;  
  }

   ::v-deep .gy-modal-header > .modal-title {
    color: #fd0; 
    font-family: "Barlow-SemiCondensed-Bold", Arial, sans-serif;
    font-size: 2.0rem;
  }

  ::v-deep .gy-modal-header > .close {
    color: #fff;
    font-size: 3.0rem;
    text-shadow: none;
    opacity: 1;
  }

  ::v-deep .gy-modal-header > .close:hover {
    color: #fd0;
    opacity: 1 !important;
  }

  ::v-deep .modal-cancel-btn {
    color: #343a40;
    border: 2px solid transparent;
    border-color: #343a40;
    background-color: transparent;
  }

  ::v-deep .modal-cancel-btn:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }

  .current-page-range {
    font-weight: bold;
    margin-top: 0.35rem;
    text-align: right !important;
  }

  @media (max-width:576px) {
    .current-page-range{
      margin-top: 0px;
      margin-bottom: 0.6rem;
    }
  }

  @media (max-width:768px) {
    .pending-users {
      margin-top: 10px;
    }
  }

  @media (min-width:768px) and (max-width:1100px) {
    h1 {
      font-size: 2.15rem;
    }
  }

  @media (min-width: 1600px) {
    .pending-users {
      width: 1600px;
      min-height: 550px;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  @media print {

  }
</style>
