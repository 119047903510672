<template>
  <div class="signup" role="main">
      <b-container>
        <b-row>
          <b-col cols="12">

            <b-form id="signUp" name="signUp" v-if="showForm" @submit="signUp">
              <div class="form-header col-sm-12 ">
                <h1>{{languageData.title}}</h1>
                <p class="small"><em>{{languageData.required}}</em></p>
              </div>

              <b-form-group class="col-sm-12 col-md-6">
                <label for="firstName">{{languageData.fn}}</label>
                <b-form-input id="firstName" v-model="form.firstName" :disabled="loading" type="text"></b-form-input>
              </b-form-group>

              <b-form-group class="col-sm-12 col-md-6">
                <label for="lastName">{{languageData.ln}}</label>
                <b-form-input id="lastName" v-model="form.lastName" :disabled="loading" type="text"></b-form-input>
              </b-form-group>

              <b-form-group class="col-sm-12 col-md-6">
                <label for="email">{{languageData.email}}</label>
                <b-form-input id="email" v-model="form.email" :disabled="loading" type="email"></b-form-input>
              </b-form-group>

              <b-form-group class="col-sm-12 col-md-6">
                <label for="emailConfirm">{{languageData.email_confirm}}</label>
                <b-form-input id="emailConfirm" v-model="form.emailConfirm" :disabled="loading" type="email"></b-form-input>
              </b-form-group>

              <b-form-group class="col-sm-12 col-md-6">
                <label for="dealerId">{{languageData.dealer_id}}</label>
                <b-form-input id="dealerId" v-model="form.dealerId" :disabled="loading" type="text"></b-form-input>
              </b-form-group>

              <b-form-group class="form-buttons col-sm-12">
                <vue-recaptcha :loadRecaptchaScript="true" @verify="onVerify" sitekey="6LcOPuseAAAAACKOgQDVL0Wx4l3XB2scmIbpgX58" ></vue-recaptcha>
                
                <p class="invalid-msg red">{{this.invalidField}}</p>

                <button v-if="!loading" class="btn" type="submit">{{languageSharedData.sign_up}}
                </button>
                <b-button v-if="loading" class="btn disabled" type="submit">
                  <b-spinner :label="languageSharedData.loading"></b-spinner> {{languageSharedData.loading}}
                </b-button>
              </b-form-group>
            </b-form>

            <div class="pass col" v-if="showThanks && !showForm">
              <h2 class="success">{{languageData.thank_you}}</h2>
              <p>{{languageData.notification}}</p>
            </div>

            <div class="fail col" v-if="showError && !showForm">
              <h2 class="error"><b-icon-x-circle-fill></b-icon-x-circle-fill> {{languageSharedData.error}}</h2>
              <p>{{errorMessage}}</p>
            </div> 

          </b-col>

        </b-row>
      </b-container>
  </div>
</template>

<script>
  import dataService from '../services/data.service';

  import VueRecaptcha from 'vue-recaptcha';

  export default {
    name: 'Signup',
    metaInfo () {
      return {
        title: this.languageData.page_title + ' | ' + this.$store.getters["appName"],
        meta: [
          { name: 'description', content: this.languageData.page_description }
        ]
      }
    },
    components : {
      VueRecaptcha
    },
    data() {
      return {
        form : {
          firstName: '',
          lastName: '',
          email: '',
          emailConfirm: '',
          dealerId: '',
          gResponse: ''
        },
        loading: false,
        showForm: true,
        invalidField: ' ',
        showThanks: false,
        showError: false,
        errorMessage: '',
        dealer: []
      }
    },
    methods : {
      onVerify: function (response) {
        if (response) this.form.gResponse = response
      },
      signUp: async function (evt) {
        evt.preventDefault()
        this.loading = true
        let formData = this.form
        this.invalidField = ''

        // input content validation
        for(let key in this.form) {
          // check for empty key values
          if(this.form[key] === '') {
            // found an empty form field
            this.loading = false
            if (key == 'gResponse') {
              // reCaptcha not verified
              this.invalidField = this.languageData.use_recaptcha
            } else {
              this.invalidField = this.languageData.required_error
            }
            return false;
          }
        }

        // confirm emails match
        if (this.form.email.toLowerCase() != this.form.emailConfirm.toLowerCase()) {
          this.loading = false
          this.invalidField = this.languageData.match_email
          return false;
        }

        try {
          let response = await dataService.signUp(formData);
          this.loading = false
          this.showForm = false

          if (response.data.error) {
            this.errorMessage = response.data.error
            this.showError = true
          } else {
            this.showThanks = true
            window.dataLayer.push({ 'event': 'formSubmitted', 'formName': 'Sign Up' })
          }
        } catch {
            this.loading = false;
            this.errorMessage = this.languageSharedData.try_again;
            this.showError = true;
        }
      }
    },
    computed: {
      languageSharedData: function () {
        return this.$store.state.lang.languageData['shared'][this.$store.state.lang.language];
      },
      languageData: function () {
        return this.$store.state.lang.languageData[this.$route.name.toLowerCase()][this.$store.state.lang.language];
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .signup {
    margin-top: 30px;
    min-height: 400px;
  }
  h1 {
    color: #274ba7;
  }
  .form-group {
    float: left;
  }
  .form-control:disabled, .form-control[readonly] {
    cursor: not-allowed;
  }
  .form-buttons {
    margin-bottom: 1.5rem;
    margin-top: 15px;
  }
  .invalid-msg {
    margin-top: 1.5rem;
    margin-bottom: .35rem;
  }
  .btn {
    margin-left: 2px;
    min-width: 225px;
  }
  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
  .form-control {
    border-radius: 0px;
    height: 45px;
    font-size: 18px
  }
  .fail, .pass {
    margin-top: 25px;
  }
  @media (min-width: 993px) {
    .signup {
      min-height: 650px;
      padding-top: 20px;
    }
    h1 {
      font-size: 3rem;
    }
  }
</style>