<template>
  <div class="dashboard" role="main">

    <ChooseDealer v-if="chooseCustomer && languageData" :i18n="languageData" :i18n_="languageSharedData" @view-dashboard="viewDashboard" />

    <b-container fluid v-if="showDashboard && !chooseCustomer && !loading">
      <b-row class="cust-info">
        <b-col sm="12" md="12" lg="7">
          <h1>{{dealer.CAN_CAR_DLR_NAME}}</h1>
          <a v-if="$store.getters.isUserAdmin" class="change-customer" v-on:click="showChooseDealer()" >{{languageData.link1}}<b-icon-chevron-compact-right></b-icon-chevron-compact-right></a>
          <a class="print" v-on:click="print()">{{languageData.link2}}<b-icon-chevron-compact-right></b-icon-chevron-compact-right></a>
          <b-row>
            <b-col class="details" sm=12 md="7" lg="5" xl="4">
              <b-col>{{languageData.label_custID}}: <span>{{dealer.WEB_CUST_ID}}</span></b-col>
              <b-col>{{languageData.label_dealerID}}: <span>{{dealer.CAN_CAR_DLR_ID}}</span></b-col>
            </b-col>
            <b-col class="details" sm=12 md="5" lg="5" xl="5">
              <b-col>{{languageData.label_location}}: <span>{{dealer.CAN_CAR_DLR_LOC_CNT}}</span></b-col>
              <b-col>{{languageData.label_incentiveLvl}}: <span>{{dealer.INCNTV_QLFY_TIER}}</span></b-col>
            </b-col>
            <b-col class="details" sm=12>
              <b-col>{{languageData.label_salesRepName}}: <span class="blue">{{dealer.SO_SLSPRSN}}</span></b-col>
              <b-col>
                <Calculator :dealerDataForCalc="dealer"/>
              </b-col>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="annual" sm="12" md="12" lg="5">
          <h3>{{languageData.title_h3}}</h3>
          <div class="gy-bar current">
            <span class="year blue">{{dealer.CURR_YR}}</span> 
            <b-progress height="2.25rem" :max="dealer.NET_PRCH_CURR_ANNUAL_QTY" show-value class="active">
              <b-progress-bar :value="dealer.NET_PRCH_KELLY_CURR_ANNUAL_QTY" variant="success"></b-progress-bar>
              <b-progress-bar :value="dealer.NET_PRCH_COOPER_CURR_ANNUAL_QTY" variant="dark"></b-progress-bar>
              <b-progress-bar :value="dealer.NET_PRCH_DNLP_CURR_ANNUAL_QTY" variant="danger"></b-progress-bar>
              <b-progress-bar :value="dealer.NET_PRCH_GDYR_CURR_ANNUAL_QTY"></b-progress-bar>
            </b-progress> 
            <span class="max blue">{{dealer.NET_PRCH_CURR_ANNUAL_QTY | commas}}</span> 
          </div>
          <div class="gy-bar">
            <span class="year">{{dealer.PRIOR_YR}}</span> 
            <b-progress height="1.35rem" :max="dealer.NET_PRCH_PRIOR_ANNUAL_QTY" show-value class="active">
              <b-progress-bar :value="dealer.NET_PRCH_KELLY_PRIOR_ANNUAL_QTY" variant="success"></b-progress-bar>
              <b-progress-bar :value="dealer.NET_PRCH_COOPER_PRIOR_ANNUAL_QTY" variant="dark"></b-progress-bar>
              <b-progress-bar :value="dealer.NET_PRCH_DNLP_PRIOR_ANNUAL_QTY" variant="danger"></b-progress-bar>
              <b-progress-bar :value="dealer.NET_PRCH_GDYR_PRIOR_ANNUAL_QTY"></b-progress-bar>
            </b-progress> 
            <span class="max">{{dealer.NET_PRCH_PRIOR_ANNUAL_QTY | commas}}</span> 
          </div>
          <div class="gy-bar">
            <span class="year">{{dealer.PRIOR2_YR}}</span> 
            <b-progress height="1.35rem" :max="dealer.NET_PRCH_PRIOR2_ANNUAL_QTY" show-value class="active">
              <b-progress-bar :value="dealer.NET_PRCH_KELLY_PRIOR2_ANNUAL_QTY" variant="success"></b-progress-bar>
              <b-progress-bar :value="dealer.NET_PRCH_COOPER_PRIOR2_ANNUAL_QTY" variant="dark"></b-progress-bar>
              <b-progress-bar :value="dealer.NET_PRCH_DNLP_PRIOR2_ANNUAL_QTY" variant="danger"></b-progress-bar>
              <b-progress-bar :value="dealer.NET_PRCH_GDYR_PRIOR2_ANNUAL_QTY"></b-progress-bar>
            </b-progress> 
            <span class="max">{{dealer.NET_PRCH_PRIOR2_ANNUAL_QTY | commas}}</span> 
          </div>
          <div class="gy-bar">
            <span class="year">{{dealer.PRIOR3_YR}} </span> 
            <b-progress height="1.35rem" :max="dealer.NET_PRCH_PRIOR3_ANNUAL_QTY" show-value class="active">
              <b-progress-bar :value="dealer.NET_PRCH_KELLY_PRIOR3_ANNUAL_QTY" variant="success"></b-progress-bar>
              <b-progress-bar :value="dealer.NET_PRCH_COOPER_PRIOR3_ANNUAL_QTY" variant="dark"></b-progress-bar>
              <b-progress-bar :value="dealer.NET_PRCH_DNLP_PRIOR3_ANNUAL_QTY" variant="danger"></b-progress-bar>
              <b-progress-bar :value="dealer.NET_PRCH_GDYR_PRIOR3_ANNUAL_QTY"></b-progress-bar>
            </b-progress> 
            <span class="max">{{dealer.NET_PRCH_PRIOR3_ANNUAL_QTY | commas}}</span> 
          </div>
          
        </b-col>
      </b-row>

      <b-row class="prog-info">
        <b-col class="gy-card" sm="12" md="4">
          <div class="card base" data-aos="fade-right" data-aos-delay="300" data-aos-once="true">
            <div class="card-body">
              <h3 class="card-title">{{languageData.title_baseh3}}</h3>
              <p class="year">{{dealer.PRIOR_YR}}: {{languageData.base_subTxt}} {{dealer.BASE_QLFY_TIER}}</p>
              <b-col class="details" cols="7" sm="8" md="8">
                <b-col>{{languageData.base_txt1}}</b-col>
                <b-col><img src="../assets/images/icons/goodyear-wingfoot.svg" :alt="languageData.goodyear_icon" /><img src="../assets/images/icons/dunlop.svg" :alt="languageData.dunlop_icon" /></b-col>
                <b-col><img src="../assets/images/icons/cooper-logo.svg" :alt="languageData.cooper_icon" /> <span class="small"></span></b-col>
                <b-col><img src="../assets/images/icons/kelly.svg" :alt="languageData.kelly_icon" /></b-col>
              </b-col>
              <b-col class="details" cols="5" sm="4" md="4">
                <b-col>{{dealer.NET_PRCH_PRIOR_ANNUAL_QTY | commas}}</b-col>
                <b-col>{{dealer.GDYR_DNLP_PRIOR_ANNUAL_QTY | commas}}</b-col>
                <b-col>{{dealer.NET_PRCH_COOPER_PRIOR_ANNUAL_QTY | commas}}</b-col>
                <b-col>{{dealer.NET_PRCH_KELLY_PRIOR_ANNUAL_QTY | commas}}</b-col>
              </b-col>
            </div>
          </div>
        </b-col>

        <b-col class="gy-card" :class="isIncentive ? 'active' : 'locked last'" sm="12" md="4">
          <div class="card" data-aos="fade-right" data-aos-delay="300" data-aos-once="true">
            <div class="card-body">
              <h3 class="card-title">{{languageData.title_currentLvlh3}} {{dealer.INCNTV_QLFY_TIER}}</h3>
              <p class="year">{{dealer.TIER_UNITS}} {{languageData.currentLvl_subTxt}}</p>
              <b-col class="details" cols="7" sm="8" md="8">
                <b-col>{{languageData.currentLvl_txt1}}</b-col>
                <b-col><img src="../assets/images/icons/goodyear-wingfoot.svg" :alt="languageData.goodyear_icon" /><img src="../assets/images/icons/dunlop.svg" :alt="languageData.dunlop_icon" /> <span class="small"> {{languageData.currentLvl_txt2}}</span></b-col>
                <b-col><img src="../assets/images/icons/cooper-logo.svg" :alt="languageData.cooper_icon" /> <span class="small"> {{languageData.currentLvl_txt2}}</span></b-col>
                <b-col><img src="../assets/images/icons/kelly.svg" :alt="languageData.kelly_icon" /> <span class="small"> {{languageData.currentLvl_txt2}}</span></b-col>
                <b-col v-if="isIncentive">{{languageData.currentLvl_txt3}}</b-col>
              </b-col>
              <b-col class="details" cols="5" sm="4" md="4">
                <b-col><strong>{{dealer.NET_PRCH_CURR_ANNUAL_QTY | commas}}</strong></b-col>
                <b-col><strong>${{dealer.INCNTV_GDYR_DNLP_AMT}}</strong></b-col>
                <b-col><strong>${{dealer.INCNTV_GDYR_DNLP_AMT}}</strong></b-col>
                <b-col><strong>${{dealer.INCNTV_KELLY_AMT}}</strong></b-col>
                <b-col v-if="isIncentive"><strong>{{dealer.INCNTV_CURR_PAY_AMT | currency | commas}}</strong></b-col>
              </b-col>
            </div>
          </div>
          <b-progress v-if="isIncentive" height="2rem" :value="dealer.NET_PRCH_CURR_ANNUAL_QTY" :max="dealer.INCNTV_TARGET_QTY" show-value></b-progress> <span v-if="isIncentive" class="max">{{dealer.INCNTV_TARGET_QTY | commas}}</span> 
          <b-progress v-if="!isIncentive" height="2rem" :value="dealer.INCNTV_TARGET_QTY" :max="dealer.INCNTV_TARGET_QTY" show-value></b-progress> <span v-if="!isIncentive" class="max">{{dealer.INCNTV_TARGET_QTY | commas}}</span> 
          <div>
            <span class="note"><em>{{languageData.currentLvl_txt4}}</em></span>
          </div>
        </b-col>

        <b-col class="gy-card locked" v-if="isIncentive" sm="12" md="4">
          <div class="card" data-aos="fade-right" data-aos-delay="300" data-aos-once="true">
            <div class="card-body">
              <h3 class="card-title">{{languageData.title_levelUph3}} {{dealer.LEVEL_UP_TIER}} <b-icon-lock-fill v-if="isIncentive"></b-icon-lock-fill><b-icon-unlock-fill v-if="!isIncentive"></b-icon-unlock-fill></h3>
              <p class="year">{{dealer.LEVEL_UP_TIER_UNITS}} {{languageData.levelUp_subTxt}}</p>
              <b-col class="details" cols="7" sm="8" md="8">
                <b-col>{{languageData.levelUp_txt1}}</b-col>
                <b-col><img src="../assets/images/icons/goodyear-wingfoot.svg" :alt="languageData.goodyear_icon" /><img src="../assets/images/icons/dunlop.svg" :alt="languageData.dunlop_icon" /> <span class="small"> {{languageData.levelUp_txt2}}</span></b-col>
                <b-col><img src="../assets/images/icons/cooper-logo.svg" :alt="languageData.cooper_icon" /> <span class="small"> {{languageData.levelUp_txt2}}</span></b-col>
                <b-col><img src="../assets/images/icons/kelly.svg" :alt="languageData.kelly_icon" /> <span class="small"> {{languageData.levelUp_txt2}}</span></b-col> 
              </b-col>
              <b-col class="details" cols="5" sm="4" md="4">
                <b-col><strong>{{dealer.LEVEL_UP_UNITS_TO_UNLOCK | commas}}</strong></b-col>
                <b-col><strong>${{dealer.LEVEL_UP_GDYR_DNLP_AMT}}</strong></b-col>
                <b-col><strong>${{dealer.LEVEL_UP_GDYR_DNLP_AMT}}</strong></b-col>
                <b-col><strong>${{dealer.LEVEL_UP_KELLY_AMT}}</strong></b-col>
              </b-col>
            </div>
          </div>
          <b-progress height="2rem" :value="dealer.NET_PRCH_CURR_ANNUAL_QTY" :max="dealer.LEVEL_UP_TARGET_QTY" show-value></b-progress> <span class="max">{{dealer.LEVEL_UP_TARGET_QTY | commas}}</span> 
          <div>
            <span class="note"><em>{{languageData.levelUp_txt3}}</em></span>
          </div>
        </b-col>

        <b-col class="gy-card gy-grow active" v-if="!isIncentive" sm="12" md="4">
          <div class="card" data-aos="fade-right" data-aos-delay="300" data-aos-once="true">
            <div class="card-body">
              <h3 class="card-title">{{languageData.level}} {{dealer.INCNTV_QLFY_TIER}} {{languageData.complete}} <b-icon-star></b-icon-star></h3>
              <p class="year">{{languageData.continue_selling}}</p>
              <b-col class="text-center">
                <b-col><strong>{{dealer.INCNTV_CURR_PAY_AMT | currency | commas}}</strong></b-col>
                <b-col>{{languageData.curr_payout_amnt}}</b-col>
              </b-col>
            </div>
          </div>
          <b-progress height="2rem" :value="dealer.NET_PRCH_CURR_ANNUAL_QTY" :max="dealer.GROWTH_MIN_QTY" show-value></b-progress> <span class="max"> + </span> 
          <div>
            <span class="note"><em>{{languageData.progress}}</em></span>
          </div>
        </b-col>
      </b-row>

      <div class="gy-calc">
      </div>
    </b-container>

    <b-container v-if="loading">
      <b-row><b-col class="text-center">
        <b-spinner :label="languageSharedData.loading"></b-spinner>
        <p><strong>{{languageSharedData.loading}}</strong></p>
      </b-col></b-row>
    </b-container>

    <b-container v-if="showError && !showDashboard && !chooseCustomer && !loading">
      <h5 class="error">{{languageSharedData.error}}</h5>
      <p>{{languageSharedData.try_again}}</p>
    </b-container>

  </div>
</template>

<script>
  import ChooseDealer from './admin/ChooseDealer.vue'
  import Calculator from './Calculator.vue'
  import dataService from '../services/data.service';

  export default {
    name: 'Dashboard',
    props: {
      dealerDataForCalc:  {
        type: Object
      }
    },
    metaInfo () {
      return {
        title: this.languageData.page_title + ' | ' + this.$store.getters["appName"]
      }
    },
    components: { 
      ChooseDealer,
      Calculator
    },
    data() {
      return {
        dealer: "",
        chooseCustomer: false,
        loading: false,
        showDashboard: false,
        showError: false,
        isIncentive: false
      }
  },
  async mounted () {
    if (this.$store.getters.isUserAdmin) {
      this.chooseCustomer = true;
    } else {
      this.viewDashboard();
    }
  },
  computed: {
    isDisabled: function() {
      return !(this.chosenSalesRep && this.chosenDealer);
    },
    languageSharedData: function () {
      return this.$store.state.lang.languageData['shared'][this.$store.state.lang.language];
    },
    languageData: function () {
      return this.$store.state.lang.languageData[this.$route.name.toLowerCase()][this.$store.state.lang.language];
    }
  },
  methods : {
    showChooseDealer: function () {
      this.chooseCustomer = true;
      this.showDashboard = false;
    },
    print: function() {
      window.print()
    },
    viewDashboard: function (dealer) {
      this.getDealerData(dealer)
      this.chooseCustomer = false
      this.showDashboard = true
    },
    getDealerData: async function (dealer) {
      this.loading = true;

      try {
        let dealerResponse;

        // if not admin/sales rep use user nonsig to search dealer
        if (this.$store.getters.isUserAdmin)
          dealerResponse = await dataService.getDealerData(dealer);
        else
          dealerResponse = await dataService.getDealerData();

        if (dealerResponse.data === 0) {
            this.showError = true;
        } else {
          this.dealer = dealerResponse.data;

          if(this.dealer.INCENTIVE_OR_GROWTH.toLowerCase() === 'incentive') {
            this.isIncentive = true;
          } else {
            this.isIncentive = false;
          }
          this.showDashboard = true;
        }
        this.loading = false;
      } catch (error) {
        if (error) {
          this.loading = false;
          this.showError = true
        }
      }
    }
  },
  filters: {
    currency(value) {
      // filter to add dollar sign to and remove decimal places
      if (value) {
        value = Math.trunc(value)
        return '$' + value
      } 
      
    },
    commas(value) {
      // filter to add commas to numbers
      let num = value
      if (num) {
        let commas = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        value = commas
        return value
      }
      return false
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dashboard {
  margin-top: 50px;
  min-height: 400px;
}
.card {
  margin-bottom: 25px;
  min-height: 246px;
}
.card .details {
  float: left;
  padding-left: 0px;
  padding-right: 0px;
}
.card .details .col {
  margin-bottom: 15px;
}
.cust-info h1 {
  margin-bottom: 0px;
}
.cust-info .change-customer, .cust-info .print {
  font-weight: bold;
}
.cust-info .print {
  margin-left: 15px;
}
.cust-info .change-customer:hover, .cust-info .print:hover {
  cursor: pointer;
}
.cust-info .details span {
  font-family: "Barlow-Bold", Arial, sans-serif;
}
.annual .gy-bar {
  margin-bottom: 7px;
}
.annual .gy-bar.current {
  margin-bottom: 15px;
}
.annual .gy-bar.current .year {
  font-weight: bold;
}
.annual .gy-bar.current .max {
  font-weight: bold;
  font-style: italic;
}
.annual .year {
  margin-right: 5px;
  font-size: 16px;
  display: inline-block;
  width: 9%;
}
.annual .max {
  font-weight: normal;
  font-size: 16px;
  margin-left: 8px;
}
.annual .progress {
  width: 70%;
}
.prog-info, .gy-calc {
  margin-top: 45px;
}
.base .card-title {
  margin-bottom: 0px;
  text-align: center;
}
.base .year, .active .year, .locked .year{
  margin-top: -5px;
  text-align: center;
  font-size: 85%;
}
.active .card {
  border: 3px solid #274ba7;
}
.locked, .locked .card {
  color: rgba(0,0,0,.4);
}
.locked.last, .locked.last .card {
  color: #000;
}
.card-title, h4 {
  font-family: "Barlow-Bold", Arial, sans-serif;
}
.active .card .card-title, .locked .card .card-title {
  text-align: center;
  margin-top: 11px;
  margin-bottom: 0px;
  height: 40px;
}
::v-deep .progress {
  width: 85%;
  display: inline-flex;
}
::v-deep .progress .progress-bar {
  min-width: 4%;
}
::v-deep .cust-info .progress .progress-bar {
  background-color: #274ba7;
}
::v-deep .active .progress .progress-bar {
  background-color: #274ba7;
  font-size: 14px;
}
::v-deep .locked .progress .progress-bar {
  background-color: #A9A9A9;
  font-size: 14px;
}
.max {
  font-weight: bold;
  float: right;
}
.bar-desc {
  margin-bottom: -7px;
}
.note {
  font-size: 75%;
}

@media (max-width:768px) {
  .dashboard {
    margin-top: 10px;
  }
  h1 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  .cust-info .details .col {
    padding-left: 3px;
    padding-right: 0px;
  }
}

@media (min-width: 768px) and (max-width:992px) {
  .annual .year {
    width: 11%;
  }
  .prog-info .details .col {
    padding-left: 1px;
    padding-right: 1px;
  }
  .prog-info .gy-card {
    max-width: 25.33%;
    flex: 0 0 25.33%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .prog-info .gy-card.active, .prog-info .gy-card.locked {
    max-width: 37.33%;
    flex: 0 0 37.33%;
  }
  .prog-info .card-body {
    padding: 5px;
    font-size: 17px;
  }
  .prog-info .bar-desc, .prog-info .max {
    font-size: 17px;
  }
}
@media (max-width: 991px) {
  .annual {
    margin-top: 40px;
    margin-bottom: 10px;
  }
}
@media (min-width:500px) and (max-width: 991px) {
  .annual .progress {
    width: 75%;
  }
}
@media (min-width:768px) and (max-width:1100px) {
  h1 {
    font-size: 2.15rem;
  }
  .annual h3 {
    font-size: 1.5rem;
  }
}

@media (max-width:1216px) {
  .cust-info .details {
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  .card {
    min-height: 257px;
  }
  .annual .year {
    width: 7%;
  }
}
@media (min-width: 1300px) {
  .annual .progress {
    width: 80%;
  }
}
@media (min-width: 1600px) {
  .dashboard {
    width: 1600px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media print {
  .cust-info .change-customer, .cust-info .print {
    display: none;
  }
  ::v-deep .progress {
    border: 1px solid #000;
  }
  ::v-deep .progress .progress-bar {
    color: #000;
  }
  ::v-deep .cust-info .progress .progress-bar {
    border: 3px solid #274ba7;
  }
  ::v-deep .cust-info .progress .progress-bar.bg-success {
    border: 3px solid #28a745;
  }
  ::v-deep .cust-info .progress .progress-bar.bg-danger {
    border: 3px solid #dc3545;
  }
  ::v-deep .active .progress .progress-bar {
    border: 3px solid #274ba7;
  }
  ::v-deep .locked .progress .progress-bar {
    border: 3px solid #A9A9A9;
  }
}

</style>
