import axios from 'axios';
import { msalPluginInstance } from "../plugins/MSALPlugin"
import router from '../router';

const getTokenAndCallApi = async (config) => {
  return await msalPluginInstance.acquireAccessToken(async (access_token) => {
    // azure swa replaces 'authorization' header with its own so we use custom one
    config.headers = {
      'x-custom-authorization':  `Bearer ${access_token}`
    };
    try {
      return await axios(config);
    } catch (err) {
      if(err.response.status === 403){
        router.push({ name: 'forbidden'});
      } else {
        //do we need to handle error 401? 
      }
      //return data to avoid errors in console
      return {data: {data: {length: 0}}};
    }
  });
};

export default {

  // GET requests

  /**
   * sales rep list
   */
  async getSalesReps() {
    return getTokenAndCallApi({url: '/api/getSalesReps', method: 'GET'});
  },
  
   /**
   * pending user list
   */
  async getPendingUsers(admin) {
    return getTokenAndCallApi({url: '/api/getPendingUsers', method: 'POST', data: {admin: admin }});
  },

  // POST requests

  /**
   * dealer list
   * 
   * @param chosenSalesRep chosen sales rep
   */
  async getDealers(chosenSalesRep) {
    return getTokenAndCallApi({url: '/api/getDealers', method: 'POST', data: {chosenSalesRep: chosenSalesRep }});
  },

  /**
   * dealer data
   * 
   * @param {String} chosenDealer chosen dealer
   */
  async getDealerData(chosenDealer) {
    if(chosenDealer)
      return getTokenAndCallApi({url: '/api/getDealerData', method: 'POST', data: {chosenDealer: chosenDealer }});
      
    return getTokenAndCallApi({url: '/api/getDealerData', method: 'POST'});
    
    
  },

  /**
   * sign up dealer
   * 
   * @param formData form data
   */
  signUp(formData) { 
    return axios
    .post(`/api/signUp`, formData)
    .then(response => {
      return response
    })
    .catch(error => { 
      throw error 
    });
  },

  async sendContactUs(data) {
    return getTokenAndCallApi({url: '/api/send-email', method: 'POST', data: {data: data }});
  },

  /**
   * pending nonsig users list
   */
  async getPendingNonsigUsers() {
    return getTokenAndCallApi({url: '/api/getPendingNonsigUsers', method: 'POST'});
  },

  /**
   * Resolve pending nonsig user
   */
  async resolvePendingNonsigUser(user, action) {
    return getTokenAndCallApi({url: '/api/resolvePendingNonsigUser', method: 'POST', data: {user, action}});
  },

  /**
   * approve user and remove it from temp table
   */
  async approve(userId, email, oid, nonsig) {
    return getTokenAndCallApi({url: '/api/addToGroup', method: 'POST', data: {userId: userId, email: email, CreatedBy: oid, nonsig: nonsig}});
  },

  /**
   * deny user and remove it from temp table
   */
  async deny(userId) {
    return getTokenAndCallApi({url: '/api/deleteUser', method: 'DELETE', data: {userId: userId}});
  }

}