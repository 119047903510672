<template>
  <div class="error-404">
      <b-container>
        <b-row>
          <b-col sm="12">
            <h1>{{languageData.title}}</h1>
            <p>{{languageData.message}}</p>
            <a class="btn gy-primary backHome" href="/">{{languageData.back_home_btn}}</a>
          </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
  export default {
    name: 'error-404',
    metaInfo () {
      return {
        title: this.languageData.page_title + ' | ' + this.$store.getters["appName"],
      }
    },
    computed: {
      languageData: function () {
        return this.$store.state.lang.languageData[this.$route.name.toLowerCase()][this.$store.state.lang.language];
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .error-404 {
    background-color: #fff;
    min-height: 500px;
    padding-top: 50px;
    margin-bottom: 25px;
    text-align: center;
  }
  h1 {
    font-family: 'Barlow', Arial, sans-serif;
    font-weight: bold;
    font-size: 32px;
    color: #000;
  }
  .btn.gy-primary {
    color: #000;
  }
</style>
