import * as msal from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import store from '../../store'

let msalInstance;

export let msalPluginInstance;

export class MsalPlugin {
    #msalOptions = {};

    install(vue, options) {
        if (!options) {
            throw new Error("MsalPluginOptions must be specified");
        }
        this.#msalOptions = options;
        this.initialize();
        msalPluginInstance = this;
        vue.prototype.$msal = msalPluginInstance;
    }

    initialize() {
        const msalConfig = this.#msalOptions.msalConfig;
        msalInstance = new msal.PublicClientApplication(msalConfig);
    }

    /**
     * Returns true if signIn performed without loginRedirect, otherwise returns false 
     */
    async signIn() {
        const loginRequest = {
            scopes: this.#msalOptions.apiScopes
        };
        const redirectResponse = await msalInstance.handleRedirectPromise();
        // redirect promise fulfilled only after redirect
        // and getAllAccounts returns an Object also after redirect
        // so we need to get into signIn method twice: on login and after redirect
        if(!this.isAuthenticated()) {
            // no user signed in
            await msalInstance.loginRedirect(loginRequest);
            return false;
        } else {
            // update store auth
            store.commit("changeAuth", msalPluginInstance);

            if (redirectResponse) {
                this.storeUserAuth(redirectResponse.idToken, redirectResponse.idTokenClaims.emails[0], redirectResponse.idTokenClaims.extension_Nonsig, redirectResponse.idTokenClaims.name, redirectResponse.idTokenClaims.oid);
            } else {
                const tokenResponse = await this.acquireTokenResponse();
                this.storeUserAuth(tokenResponse.idToken, tokenResponse.idTokenClaims.emails[0], tokenResponse.idTokenClaims.extension_Nonsig, tokenResponse.idTokenClaims.name, tokenResponse.idTokenClaims.oid);
            }
        }
        return true;
    }

    async signOut() {
        await msalInstance.logout();
    }

    async signOutAndAccessDenied(accessDeniedError) {
        if(accessDeniedError === 'USER_NONSIG_PENDING') {
            await msalInstance.logoutRedirect({
                account: msalInstance.getAllAccounts()[0],
                postLogoutRedirectUri: window.location.origin + '/logout?error=user-nonsig-pending'
            });
            return;
        }

        await msalInstance.logoutRedirect({
            account: msalInstance.getAllAccounts()[0],
            postLogoutRedirectUri: window.location.origin + '/logout?error=access-denied'
        });
    }

    storeUserAuth(idToken, email, nonsig, name, oid) {
        // update store user
        store.commit("setUserIdToken", idToken);
        store.commit("setUserEmail", email);
        store.commit("setUserNonsig", nonsig);
        store.commit("setUserName", name);
        store.commit("setUserOid", oid);
    }

    async acquireToken() {
        const redirectResponse = await msalInstance.handleRedirectPromise();
        if (redirectResponse !== null) {
            // acquire token silent success
            let accessToken = redirectResponse.accessToken;
            // update store user
            this.storeUserAuth(redirectResponse.idToken, redirectResponse.idTokenClaims.emails[0], redirectResponse.idTokenClaims.extension_Nonsig, redirectResponse.idTokenClaims.name, redirectResponse.idTokenClaims.oid);
           return accessToken;
        } else {
            const accessTokenRequest = {
                account: msalInstance.getAllAccounts()[0],
                scopes: this.#msalOptions.apiScopes
            };

            try {
                let accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
                
                // update store user
                this.storeUserAuth(accessTokenResponse.idToken, accessTokenResponse.idTokenClaims.emails[0], accessTokenResponse.idTokenClaims.extension_Nonsig, accessTokenResponse.idTokenClaims.name, accessTokenResponse.idTokenClaims.oid);

                return accessTokenResponse.accessToken;
            } catch(error) {
                // acquire token silent failure, and send an interactive request
                if (error instanceof InteractionRequiredAuthError) {
                    msalInstance.acquireTokenRedirect(accessTokenRequest);
                }
            }
        }
    }

    async acquireAccessToken(callApi) {
        const redirectResponse = await msalInstance.handleRedirectPromise();
        if (redirectResponse !== null) {
            // acquire token silent success
            let accessToken = redirectResponse.accessToken;
            // call your API with token
            return callApi(accessToken);
        } else {
            const accessTokenRequest = {
                account: msalInstance.getAllAccounts()[0],
                scopes: this.#msalOptions.apiScopes
            };

            try {
                let accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
                return await callApi(accessTokenResponse.accessToken);
            } catch(error) {
                // acquire token silent failure, and send an interactive request
                if (error instanceof InteractionRequiredAuthError) {
                    msalInstance.acquireTokenRedirect(accessTokenRequest);
                }
            }
        }
    }

    async acquireTokenResponse() {
        const accessTokenRequest = {
            account: msalInstance.getAllAccounts()[0],
            scopes: this.#msalOptions.apiScopes
        };

        try {
            return await msalInstance.acquireTokenSilent(accessTokenRequest);
        } catch(error) {
            if (error instanceof InteractionRequiredAuthError) {
                msalInstance.acquireTokenRedirect(accessTokenRequest);
            }
        }
    }

    isAuthenticated() {
        const accounts = msalInstance.getAllAccounts();
        return accounts && accounts.length > 0;
    }
}