<template>
  <div class="calculator" role="main">
    <b-container class="calcContainer">
      <!--Calculator Button -->
      <b-row>
        <b-col class="details calculator" sm=12>
            <b-col class="calcCol"><b-icon icon="calculator-fill" font-scale="1"></b-icon>
              <a v-b-modal.modal-lg variant="primary" v-on:click="clearInputs()" class="blue">{{languageData.calc.lnk}}</a>
            </b-col>
          
        <!--Modal body-->
          <b-modal id="modal-lg" size="lg" hide-footer header-class="myHeaderClass">
            <div class="calculator-modal">
             <b-row>
               <b-col cols="12" class="text-center">
                 <h1>{{languageData.calc.title}}</h1>
               </b-col>

               <b-col cols="12">
                  
                <b-row>
                  <b-col cols="12" md="6">
                    
                      <select @change="filterData($event)" v-model="levelSelected">
                      <option value="lvl1">{{languageData.calc.level}} {{this.calculatorData.dealerDataForCalc.INCENTIVE_LEVEL_1}}</option>
                      <option value="lvl2">{{languageData.calc.level}} {{this.calculatorData.dealerDataForCalc.INCENTIVE_LEVEL_2}}</option>
                      <option value="lvl3">{{languageData.calc.level}} {{this.calculatorData.dealerDataForCalc.INCENTIVE_LEVEL_3}}</option>
                      <option value="lvl4">{{languageData.calc.level}} {{this.calculatorData.dealerDataForCalc.INCENTIVE_LEVEL_4}}</option>
                      <option value="lvl5">{{languageData.calc.level}} {{this.calculatorData.dealerDataForCalc.INCENTIVE_LEVEL_5}}</option>
                    </select>
                  </b-col>
                  
                  <b-col cols="12" md="6">
                    <span class="unitsToUnlockRight"><h3>{{languageData.calc.units_needed}} <u class="unlockNumber">{{unitsToUnlock}}</u> <b-icon icon="info-circle-fill" font-scale=".65" v-b-tooltip.hover.topright="languageData.calc.units_needed_hover"></b-icon></h3></span>
                  </b-col>
                </b-row>
                <br>
                 <b-row class="calcspecs text-center">
                    <span> <b-col class="images"><img src="../assets/images/icons/goodyear-wingfoot.svg" :alt="languageData.goodyear_icon" /><img src="../assets/images/icons/dunlop.svg" :alt="languageData.dunlop_icon" /></b-col></span>
                    <span> <b-col class="unitdollaramount">${{this.levelData.goodyearDunlopPrice}} <small class="perUnit">{{languageData.calc.per_unit}}</small></b-col></span>
                    <span> <b-col class="unitamount">{{this.goodyearDunlopCurrentUnits}}</b-col></span>
                    <span> <b-col class="symbol">+</b-col></span>
                    <span> <b-col><b-form-input v-model="gyDunlopInput" type="number" min="0" class="forminput" id=""></b-form-input></b-col></span>
                    <span> <b-col class="symbol">=</b-col></span>
                    <span> <b-col class="totalunits">{{answerGY}}</b-col></span>
                 </b-row>

                  <b-row class="calcspecs text-center">
                    <span> <b-col class="images"><img src="../assets/images/icons/kelly.svg" :alt="languageData.kelly_icon" /></b-col></span>
                    <span> <b-col class="unitdollaramount">${{this.levelData.kellyPrice}} <small class="perUnit">{{languageData.calc.per_unit}}</small></b-col></span>
                    <span> <b-col class="unitamount">{{this.kellyCurrentUnits}}</b-col></span>
                    <span> <b-col class="symbol">+</b-col></span>
                    <span> <b-col><b-form-input class="forminput" type="number" min="0" v-model="kellyInput" id=""></b-form-input></b-col></span>
                    <span> <b-col class="symbol">=</b-col></span>
                    <span> <b-col class="totalunits">{{answerKelly}}</b-col></span>
                  </b-row>
                <hr class="equalBar">

               <b-row>
                  <b-col cols="12" class="totalUnitsLabel text-center">
                    <span>{{languageData.calc.total_units}} <span class="numberTotalUnits"> {{totalUnits}}</span></span>
                  </b-col>
                  <b-col cols="12" class="totalUnitsLabel text-center">
                    <span>{{languageData.calc.payout_estimate}} <span class="payoutAmount">${{potentialPayoutAmount}}</span></span>
                  </b-col> 
                  <b-col cols="12 text-center">
                    <button class="startOverButton" v-on:click="clearInputs()">{{languageData.calc.restart}}</button>
                  </b-col>
                  </b-row>
               </b-col>
             </b-row>
            </div>
          </b-modal>
        </b-col>
      </b-row>
    </b-container>
    
  </div>
</template>

<script>

//import axios from 'axios'
export default {
  name: 'Calculator',
  metaInfo () {
    return {
      title: this.languageData.page_title + ' | ' + this.$store.getters["appName"],
    }
  },
  data() {
    return {
    calculatorData: this.$attrs,
    selectedLevel: '',
    pageName: "",
    dashboardLanguage: "",
    goodyearDunlopCurrentUnits: null,
    kellyCurrentUnits: null,
    levelSelected: '',
    gyDunlopInput: '',
    kellyInput: '',
    levelData:{goodyearDunlopPrice:null, kellyPrice:null, unitsNeededToUnlock: null}
    }
  },
  mounted () {
    // setting the level based on the tier
    this.setSelectedLevel()

  },
  methods : {
    setSelectedLevel() {
      this.selectedLevel = this.calculatorData.dealerDataForCalc.INCNTV_QLFY_TIER //setting selectedLevel = to the current incentive level
      this.goodyearDunlopCurrentUnits = this.calculatorData.dealerDataForCalc.GDYR_DNLP_CURR_ANNUAL_QTY //current gy/dunlop annual quantity
      this.kellyCurrentUnits = this.calculatorData.dealerDataForCalc.NET_PRCH_KELLY_CURR_ANNUAL_QTY //current kelly annual quantity

      if(this.selectedLevel === "0" || this.selectedLevel === "1") {
        this.levelSelected = "lvl1"
        this.levelData.goodyearDunlopPrice = this.calculatorData.dealerDataForCalc.LEVEL_1_GY_DNLP_AMT
        this.levelData.kellyPrice = this.calculatorData.dealerDataForCalc.LEVEL_1_KELLY_AMT
        this.levelData.unitsNeededToUnlock = this.calculatorData.dealerDataForCalc.LEVEL_1_UNITS_TO_UNLOCK
        } 
      else if (this.selectedLevel === "2") {
        this.levelSelected = "lvl2"
        this.levelData.goodyearDunlopPrice = this.calculatorData.dealerDataForCalc.LEVEL_2_GY_DNLP_AMT
        this.levelData.kellyPrice = this.calculatorData.dealerDataForCalc.LEVEL_2_KELLY_AMT
        this.levelData.unitsNeededToUnlock = this.calculatorData.dealerDataForCalc.LEVEL_2_UNITS_TO_UNLOCK
        }
      else if (this.selectedLevel === "3") {
        this.levelSelected = "lvl3"
        this.levelData.goodyearDunlopPrice = this.calculatorData.dealerDataForCalc.LEVEL_3_GY_DNLP_AMT
        this.levelData.kellyPrice = this.calculatorData.dealerDataForCalc.LEVEL_3_KELLY_AMT
        this.levelData.unitsNeededToUnlock = this.calculatorData.dealerDataForCalc.LEVEL_3_UNITS_TO_UNLOCK
        }
      else if (this.selectedLevel === "4") {
        this.levelSelected = "lvl4"
        this.levelData.goodyearDunlopPrice = this.calculatorData.dealerDataForCalc.LEVEL_4_GY_DNLP_AMT
        this.levelData.kellyPrice = this.calculatorData.dealerDataForCalc.LEVEL_4_KELLY_AMT
        this.levelData.unitsNeededToUnlock = this.calculatorData.dealerDataForCalc.LEVEL_4_UNITS_TO_UNLOCK
        }
      else if (this.selectedLevel === "5") {
        this.levelSelected = "lvl5"
        this.levelData.goodyearDunlopPrice = this.calculatorData.dealerDataForCalc.LEVEL_5_GY_DNLP_AMT
        this.levelData.kellyPrice = this.calculatorData.dealerDataForCalc.LEVEL_5_KELLY_AMT
        this.levelData.unitsNeededToUnlock = this.calculatorData.dealerDataForCalc.LEVEL_5_UNITS_TO_UNLOCK
        }
    },
  clearInputs() {
  //Clears all input values and sets to 0
    let self = this
    self.gyDunlopInput = 0
    self.kellyInput = 0
    this.setSelectedLevel()

  },
   filterData(event){

     //If Level # is selected, display dynamic data specific to that level
     if(event.target.value === "lvl1") {
       this.levelData.goodyearDunlopPrice = this.calculatorData.dealerDataForCalc.LEVEL_1_GY_DNLP_AMT
       this.levelData.kellyPrice = this.calculatorData.dealerDataForCalc.LEVEL_1_KELLY_AMT
       this.levelData.unitsNeededToUnlock = this.calculatorData.dealerDataForCalc.LEVEL_1_UNITS_TO_UNLOCK

     }
    else if (event.target.value === "lvl2") 
    {
       this.levelData.goodyearDunlopPrice = this.calculatorData.dealerDataForCalc.LEVEL_2_GY_DNLP_AMT
       this.levelData.kellyPrice = this.calculatorData.dealerDataForCalc.LEVEL_2_KELLY_AMT
       this.levelData.unitsNeededToUnlock = this.calculatorData.dealerDataForCalc.LEVEL_2_UNITS_TO_UNLOCK

      }
    else if (event.target.value === "lvl3") 
    {
       this.levelData.goodyearDunlopPrice = this.calculatorData.dealerDataForCalc.LEVEL_3_GY_DNLP_AMT
       this.levelData.kellyPrice = this.calculatorData.dealerDataForCalc.LEVEL_3_KELLY_AMT
       this.levelData.unitsNeededToUnlock = this.calculatorData.dealerDataForCalc.LEVEL_3_UNITS_TO_UNLOCK

      }
    else if (event.target.value === "lvl4") 
    {
       this.levelData.goodyearDunlopPrice = this.calculatorData.dealerDataForCalc.LEVEL_4_GY_DNLP_AMT
       this.levelData.kellyPrice = this.calculatorData.dealerDataForCalc.LEVEL_4_KELLY_AMT
       this.levelData.unitsNeededToUnlock = this.calculatorData.dealerDataForCalc.LEVEL_4_UNITS_TO_UNLOCK

      }
    else if (event.target.value === "lvl5") 
    {
       this.levelData.goodyearDunlopPrice = this.calculatorData.dealerDataForCalc.LEVEL_5_GY_DNLP_AMT
       this.levelData.kellyPrice = this.calculatorData.dealerDataForCalc.LEVEL_5_KELLY_AMT
       this.levelData.unitsNeededToUnlock = this.calculatorData.dealerDataForCalc.LEVEL_5_UNITS_TO_UNLOCK

      }
     }

},
computed : {
  languageData: function () {
    return this.$store.state.lang.languageData[this.$route.name.toLowerCase()][this.$store.state.lang.language];
  },
  answerGY: function() {
    //Total Goodyear and Dunlop Units + Inputed Values
   return parseInt(this.gyDunlopInput || 0) + parseInt(this.goodyearDunlopCurrentUnits) || 0
  },
  answerKelly: function() {
    //Total Kelly Units + Inputed Values
    return parseInt(this.kellyInput || 0) + parseInt(this.kellyCurrentUnits) || 0
  },
  totalUnits: function() {
    //Overall Total Goodyear and Dunlop Units + Total Kelly Tires
    return this.answerGY + this.answerKelly || 0
  },
  totalInputs: function() {
    //Overall Total Goodyear and Dunlop Units + Total Kelly Tires
    return parseInt(this.gyDunlopInput || 0) + parseInt(this.kellyInput || 0)
  },
  potentialPayoutAmount: function() {
      //Total Goodyear and Dunlop Units * Goodyear Dunlop Price
      return this.answerGY * parseInt(this.levelData.goodyearDunlopPrice) || 0
  },
  unitsToUnlock: function () {
      //Decrementing Units to Unlock Number - cap at not going lower than 0
      //return Math.max(0,[parseInt(this.levelData.unitsNeededToUnlock) - [parseInt(this.goodyearDunlopCurrentUnits) + parseInt(this.kellyCurrentUnits)]] - [parseInt(this.gyDunlopInput) +  parseInt(this.kellyInput)]) || 0
      return Math.max(0,[parseInt(this.levelData.unitsNeededToUnlock)-this.totalInputs]) || 0
  }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*Calculator Modal Styles Start*/
.calcCol.col{
  padding: 0px
}
.details .calculator .calcContainer {
  padding: 0px;
  margin-left: 0px;
}
.startOverButton {
  margin: 20px;
  background-color: #d9534f;
  border: none;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 40%);
  color: white;
  font-weight: bold;
  padding: 10px;
  text-transform: uppercase;
  font-size: 15px;
}
.numberTotalUnits {
  color: #274ba7;
}
.payoutAmount {
  color: #28a745;
}
.equalBar {
  background-color: #274ba7;

}
.calculator-modal select {
    width: 100%;
    height: 34px;
    border: none;
    border-bottom: #274ba7 2px solid;
    border: #ced5da solid 1px;
    border-bottom: #274ba7 2px solid;
    border-radius: 0.25rem;
}
.calculator-modal h1 {
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 30px;
  font-size: 30px;
}
.calculator-modal h3 {
  font-weight: bold;
  font-size: 20px;
}
.unitsToUnlockRight {
  float: right;
}
.totalUnitsLabel {
  font-weight: bold;
  font-size: 20px;
  padding: 10px 0px 10px 0px;
}
.calcspecs {
  margin-left: 80px;
  margin-top: 10px;
}
.totalunits {
  width: 80px;
  color: #274ba7;
  font-weight: bold;
}
.forminput {
  width: 80px;
}
.symbol {
  width: 10px;
  padding: 0px;
}
.unitamount  {
  width: 80px;
}
.unitdollaramount {
  width: 115px;
}
.images {
  width: 100px;
}
.blue:hover {
  text-decoration: underline;
}
.blue {
  color: #274ba7;
  text-transform: uppercase;
  padding-left: 5px;
  font-weight: bold;
  font-family: "Barlow-Bold", Arial, sans-serif;
  text-decoration: underline;
}
/*Overriding modal bootstrap styles using ::v-deep*/
::v-deep .myHeaderClass {
  background-color: #274ba7;
  border-bottom: #fd0 solid 3px;
  color: white;
}
::v-deep .myHeaderClass .close {
  color: white;
  text-shadow: none;
  opacity: 100;
}
::v-deep .modal-body {
    background-color: #fff;
    padding: 50px;
    
}
/*Calculator Modal Styles End*/


/*Start Media Queries*/
@media (max-width: 550px) {
  ::v-deep .modal-body {
    padding: 5px;
  }
  .calcspecs {
  margin: 0px;
  }
  .images {
    width: 65px;
    padding: 0px;
  }
  .unitdollaramount {
    width: 35px;
    padding: 0px;
  }
  .unitamount {
    width: 60px;
    padding: 0px;
  }
  .forminput {
    width: 65px;
  }
  .totalunits {
    width: 65px;
  }
  .perUnit {
    display: none;
  }
}
@media (max-width:550px) {

}
@media (max-width: 768px) {

}
@media (min-width: 550px) and (max-width: 990px) {
  ::v-deep .modal-body {
    padding: 15px;
  }
  .unitdollaramount {
    width: 90px;
    padding: 0px;
  }
 .calcspecs {
  margin-left: 0px;
  }
}

@media (max-width: 990px) {
.unitsToUnlockRight {
  float: left;
  padding-top: 10px;
}
}
</style>
