<template>
  <div class="logout" role="main">
    <section class="intro-section">
        <b-container>
          <div class="text-center">
            <h1>{{languageData.title}}</h1>
          </div>
        </b-container>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'Logout',
    metaInfo () {
      return {
        title: this.languageData.page_title + ' | ' + this.$store.getters["appName"]
      }
    },
    mounted() {
      // update store auth
      this.$store.commit("changeAuth", this.$msal);
      
      if (this.$route.query.error) {
        this.$router.push({ name: 'access-denied', params: { lang: this.$store.state.lang.language }, query: {error: this.$route.query.error}});
      }
    },
    computed: {
      languageData: function () {
        return this.$store.state.lang.languageData[this.$route.name.toLowerCase()][this.$store.state.lang.language];
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .logout {
    margin-top: 50px;
    min-height: 400px;
  }

  @media (min-width: 993px) {
    .logout {
      min-height: 650px;
      padding-top: 100px;
    }
  }
</style>
