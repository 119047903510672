<template>
  <div class="resources" role="main">

    <div class="mainimage">
      <h1 class="text-center">{{languageData.header_h1}}</h1>
    </div>

    <b-container class="resourcescont">
      <b-row v-if="this.$store.state.lang.language == 'en-CA'">
        <b-col v-for="item in engResources" :key="item.engResources" cols="12" md="4" sm="6">
            <a :href="item.url" target="_blank" class="downloadresource text-center" >
              <b-icon icon="file-earmark-arrow-down-fill" font-scale="3"></b-icon>
              <h5>{{item.resourceName}}</h5>            
           </a>
        </b-col>
      </b-row>
      <b-row v-if="this.$store.state.lang.language == 'fr-CA'">
        <b-col v-for="item in frResources" :key="item.frResources" cols="12" md="4" sm="6">
            <a :href="item.url" target="_blank" class="downloadresource text-center" >
              <b-icon icon="file-earmark-arrow-down-fill" font-scale="3"></b-icon>
              <h5>{{item.resourceName}}</h5>            
           </a>
        </b-col>
      </b-row>
    </b-container>
    
  </div>
</template>

<script>
  export default {
    name: 'Resources',
    metaInfo () {
      return {
        title: this.languageData.page_title + ' | ' + this.$store.getters["appName"]
      }
    },
    data() {
      return {
        pageName: "",
        engResources: [
          {url: '/pdf/goodyear-databook-en-2023.pdf', resourceName: '2023 Goodyear Data Book', region: 'en'},
          {url: '/pdf/cooper-databook-en-2023.pdf', resourceName: '2023 Cooper Tires Data Book', region: 'en'},
          {url: '/pdf/dunlop-databook-en-2023.pdf', resourceName: '2023 Dunlop Tires Data Book', region: 'en'},
          {url: '/pdf/kelly-databook-en-2023.pdf', resourceName: '2023 Kelly Tires Data Book', region: 'en'},
          {url: '/pdf/assurance-family-en-2022.pdf', resourceName: 'Assurance® Family', region: 'en'},
          {url: '/pdf/goodyear-winter-command-ultra-en-2022.pdf', resourceName: 'WinterCommand® Ultra', region: 'en'},
          {url: '/pdf/cooper-discoverer-roadtrail-at-en-2023.pdf', resourceName: 'Discoverer Road+Trail™ AT', region: 'en'},
          {url: '/pdf/goodyear-boulder-mt-en-2023.pdf', resourceName: 'Wrangler Boulder MT™', region: 'en'},
          {url: '/pdf/goodyear-duratrac-rt-en-2023.pdf', resourceName: 'Wrangler Duratrac® RT', region: 'en'}
        ],
        frResources: [
          {url: '/pdf/assurance-family-fr-2022.pdf', resourceName: 'Gamme Assurance®', region: 'fr'},
          {url: '/pdf/goodyear-winter-command-ultra-fr-2022.pdf', resourceName: 'WinterCommand® Ultra', region: 'fr'},
        ],
      }
    },
    computed : {
      languageData: function () {
        return this.$store.state.lang.languageData[this.$route.name.toLowerCase()][this.$store.state.lang.language];
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .resourcescont {
    background-color: #fff;
    padding-bottom: 100px;
  }
  .resources {
    background-color: #f1f1f1;
    background-image: url(../assets/images/background/diagonal-lines.png);
    background-repeat: repeat;
  }
  .desc {
    text-align: center;
    padding-top: 20px;
  }
  .downloadresource .b-icon {
    color: #000;
    padding-bottom: 10px;
  }
  .downloadresource {
    display: block;
    background-color: #cdcdcd;
    padding: 30px;
    margin: 10px;
    color: #000;
    border-radius: 15px;
    height: 170px;
  }
  .downloadresource:hover {
    display: block;
    background-color: #274ba7;
    padding: 30px;
    margin: 10px;
    color: #fff;
    text-decoration: none;
  }
  .downloadresource:hover .b-icon {
    color: #fff;
  }
  .mainimage h1 {
    margin-top: 0;
    text-transform: uppercase;
    padding: 7px;
    border: 7px solid #cdcdcd;
    display: inline-block;
    background-color: rgba(255,255,255,0.8);
  }
  .mainimage {
    min-height: 250px;
    padding-top: 75px;
    text-align: center;
    background-image: url(../assets/images/resources-md.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .login {
    margin-top: 40px;
    margin-bottom: 20px;
    min-height: 400px;
  }
  /*Resources Page Styles End*/

  /*Media Queries Start*/
  @media (max-width: 500px) {
    .mainimage {
      background-image: url('../assets/images/resources-xs.jpg');
    }
  }
  @media (min-width: 501px) and (max-width: 768px) {
    .mainimage {
      background-image: url('../assets/images/resources-sm.jpg');
    }
  }
  @media (min-width: 1281px) and (max-width: 1600px) {
    .mainimage {
      background-image: url('../assets/images/resources-lg.jpg');
    }
  }
  @media (min-width: 1601px) {
    .mainimage {
      background-image: url('../assets/images/resources-xl.jpg');
    }
  }
</style>
