const currentDomain = window.location.origin;
const customRedirectUri = currentDomain + '/signin';
const customLogoutRedirectUri = currentDomain + '/logout';

export const msalConfig = {
    auth: {
        clientId: "0734872b-df65-48e1-a8c7-e677ddbc6d88", // This is the ONLY mandatory field that you need to supply.
        authority: "https://signin.goodyearpass.com/ef9a246d-a764-4439-832c-614475f9f494/B2C_1_SIGN_IN", // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: ["signin.goodyearpass.com"],  // array of URIs that are known to be valid
        redirectUri: customRedirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: customLogoutRedirectUri, // Indicates the page to navigate after logout.
        validateAuthority: false,
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
}

export const msalScopes = ["openid", "https://gyb2c.onmicrosoft.com/97c45b4b-1543-443e-918b-65fdebf04f15/user"]

export const apiScopes = ["https://goodyearpass.com/e74e9497-842a-4fb8-b39f-ba667495fbb5/user_impersonation"]