<template>
  <b-container class="choose-dealer">
    <b-row>
      <b-col>
        <b-form name="chooseDealer">
            <div class="form-header">
              <h1>{{i18n.header_h1}}</h1>
              <p>{{i18n.header_txt}}<br/></p>
            </div>
            <b-form-row v-if="showCustInfo !== 0">
              <b-form-group class="col-sm-12 col-md-6">
                <label for="salesRep">{{i18n.label_salesRep}}</label>
                <b-form-select id="salesRep" class="form-control" v-model="chosenSalesRep" @change='getDealers(chosenSalesRep)' required>
                  <option v-for="salesRep in salesReps" :value="salesRep.SO_SLSPRSN" :key="salesRep.SO_SLSPRSN">
                    {{salesRep.SO_SLSPRSN}}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-form-row>

            <b-form-row v-if="showCustInfo === 2">
              <b-form-group class="col-sm-12 col-md-6">
                <label for="dealer">{{i18n.label_dealer}}</label>
                <b-form-select id="dealer" class="form-control" v-model="chosenDealer" required>
                  <option v-for="dealer in dealers" :value="dealer.CAN_CAR_DLR_ID" :key="dealer.CAN_CAR_DLR_ID">
                    {{dealer.CAN_CAR_DLR_NAME}} - {{dealer.CAN_CAR_DLR_ID}}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-form-row>

            <b-form-row v-if="showCustInfo === 2">
              <b-form-group class="form-buttons col-sm-12">
                <b-button class="btn viewDashboardBtn" :disabled="isDisabled" @click="$emit('view-dashboard', chosenDealer)">{{i18n.viewDashboard_btn}}</b-button>
              </b-form-group>
            </b-form-row>

            <b-row v-if="loading"><b-col>
              <b-spinner :label="i18n_.loading"></b-spinner>
              <p><strong>{{i18n_.loading}}</strong></p>
            </b-col></b-row>
        </b-form>
      </b-col>
    </b-row>
    <b-row v-if="showError">
      <h5 class="error">{{i18n_.error}}</h5>
      <p>{{i18n_.try_again}}</p>
    </b-row>
  </b-container>
</template>

<script>
import dataService from '../../services/data.service';

export default {
  name: 'ChooseDealer',
  props: {
    i18n: {
      type: Object,
      required: true
    },
    i18n_: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      salesReps: [],
      chosenSalesRep: "",
      dealers: [],
      chosenDealer: "",
      showCustInfo: 0,
      loading: true,
      showDealers: false,
      showError: false,
    }
  },
  mounted () {
    this.getSalesReps()
  },
  methods : {
    getSalesReps: async function () {
      this.chosenDealer = '';
      this.loading = true;

      try {
        const salesRepsResponse = await dataService.getSalesReps();
        this.loading = false;

        if (salesRepsResponse.data === 0) {
          this.showError = true;
        } else {
          // sort alphabetically
          this.salesReps = salesRepsResponse.data.sort((a, b) => a.SO_SLSPRSN.localeCompare(b.SO_SLSPRSN));
          this.showCustInfo = 1;
        }
      } catch (error) {
        if (error) {
          this.showError = true;
        }
      }
    },
    getDealers: async function (chosenSalesRep) {
      this.chosenDealer = '';
      this.loading = true;

      try {
        const dealersResponse = await dataService.getDealers(chosenSalesRep);
        this.loading = false;

        if (dealersResponse.data === 0) {
          this.showError = true
        } else {
          // sort alphabetically
          this.dealers = dealersResponse.data.sort((a, b) => a.CAN_CAR_DLR_NAME.localeCompare(b.CAN_CAR_DLR_NAME));
          this.showCustInfo = 2
        }
      } catch (error) {
        if (error) {
          this.showError = true
        }
      }
    },
  },
  computed: {
    isDisabled: function() {
      return !(this.chosenSalesRep && this.chosenDealer);
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .choose-dealer {
    min-height: 550px;
  }

  .choose-dealer p{
    margin-bottom: 25px;
  }

  .spinner-border {
    width: 4rem;
    height: 4rem;
    color: #274ba7;
  }

  @media (max-width:768px) {
    .choose-dealer {
      min-height: 450px;
      margin-top: 50px;
    }

    .spinner-border {
      margin-top: 50px;
    }
  }

</style>
