<template>
  <div class="Footer global-footer">
    <footer>
      <div class="wrapper-inner">
        <b-container>
          <b-row>
            <b-col sm="12" md="12" class="footer-desc text-center">
              <img class="footer-logo" src="../assets/images/goodyear-car-dealers-logo.png" :alt="languageSharedData.footer_cdpLogo"/>
              <img class="footer-logo dk" src="../assets/images/goodyear-car-dealers-logo-black.png" :alt="languageSharedData.footer_cdpLogo"/>
            </b-col>
          </b-row>
          
         <!--Commenting out disc section
          <b-row>
            <b-col sm="12">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <p block v-b-toggle.accordion-1 variant="info" class="disclaimers footLink">{{languageSharedData.footer_discTitle}}<b-icon v-if="!isActive" class="caretIcon" icon="caret-down" font-scale=".65"></b-icon><b-icon v-if="isActive" class="caretIcon" icon="caret-up" font-scale=".65"></b-icon></p>
                </b-card-header>
              <b-collapse @hide="isActive = false" @show="isActive = true" id="accordion-1" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>{{ languageSharedData.footer_discText }}</b-card-text>
                </b-card-body>
              </b-collapse>
              </b-card>
            </div></b-col>
          </b-row>-->
          <b-row>
            <b-col sm="6" class="terms1">
              <div>&copy; {{ new Date().getFullYear() }} {{languageSharedData.footer_goodyear}}</div>
           </b-col>
            <b-col sm="6" class="terms2">
              <a class="footLink" href="https://corporate.goodyear.com/us/en/more/terms-conditions-and-privacy-policy.html" target="_blank">{{languageSharedData.footer_terms}}</a>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  data() {
    return {
      isActive: false
    }
  },
  computed: {
    languageSharedData: function () {
      return this.$store.state.lang.languageData['shared'][this.$store.state.lang.language];
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.terms1 {
  text-align: left;
}
.terms2 {
  text-align: right;
}
.disclaimers {
  padding: 10px;
  margin-bottom: 0px;
}
.card-body {
  color: black
}
.card {
  border: none;
  border-bottom: #fff solid 1px;
  border-radius: 0px;
}
.card-header {
  background-color: #274ba7;
}
.caretIcon {
  float: right;
  padding: 10px;
  margin-right: 10px;
}
footer {
  background-color: #274ba7;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 15px 15px 20px 15px;
  overflow: hidden;
  color: #fff;
  min-height: 300px;
}
.wrapper-inner {
  max-width: 1180px;
  margin: 0 auto;
  overflow: hidden;
}

h3 {
  color: #fff;
  text-transform: none;
  letter-spacing: 1px;
}
a, .v-btn {
  color: #fff;
}

a:hover {
  color: #fd0;
}

.footer-desc {
  margin-top: 25px;
  margin-bottom: 25px;
}
.footer-logo {
  width: 250px;
}
.footer-logo.dk{
  display: none;
}
.footer-end {
  margin-top: 35px;
  font-size: 16px;
  color: #fff;
}

@media (max-width:550px) {
  .footer-logo {
    width: 90%
  }
  .terms1 {
    text-align: center;
  }
  .terms2 {
    text-align: center;
  }
}
@media (max-width: 768px) {

}
@media (min-width: 768px) {
  .footer-desc {
    padding-left: 20px;
    margin-bottom: 30px;
  }
  .footer-end {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 110px;
  }
}

@media (min-width: 980px) {
  .footer-desc {
      padding-left: 40px;
      margin-bottom: 50px;
  }
}


@media print {
  .footer {
    display: none;
    color: #000;
  }
  .footer-logo{
    display: none;
  }
  .footer-logo.dk{
    display: block;
    width: 250px;
    margin: 0 auto;
  }
  .terms, .terms a {
    color: #000;
  }
}
</style>
