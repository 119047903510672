export default {
  namespaced: true,
  state: {
    language: localStorage.getItem('language') || 'en-CA',
    languageData: {}
  },
  getters: {
    language: state => {
      return state.language
    },
    languageData: state => {
      return state.languageData
    }
  },
  mutations: {
    changeLanguage(state, payload) {
      // store language user selected in localstorage
      localStorage.setItem('language', payload);
      state.language = payload;
    },
    updateLanguageData(state, payload) {
      state.languageData[payload.content_name] = {
        'en-CA': payload.en,
        'fr-CA': payload.fr
      }
    }
  }
}