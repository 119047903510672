import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { msalConfig, msalScopes, apiScopes } from './authConfig'
import { MsalPlugin } from './plugins/MSALPlugin'
import VueMeta from 'vue-meta'
import axios from 'axios'

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/styles/style.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueMeta)

axios.interceptors.request.use((config) => {
  config.headers['x-custom-lang'] = store.state.lang.language
  return config;
});

const msalOptions = {
  msalConfig: msalConfig,
  msalScopes: msalScopes,
  apiScopes: apiScopes,
};
Vue.use(new MsalPlugin(), msalOptions);

new Vue({ el: '#app', router, store, render: h => h(App) })
